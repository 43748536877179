<template>
  <VxDialog
    v-model="modelValue"
    :title="$t('settings.store.storeDialog.title')"
    :align-actions-right="false"
    persistent
    fullscreen
    no-back
  >
    <v-container class="px-0">
      <VxForm ref="form" @submit="ok">
        <v-row>
          <v-col cols="12" md="6" xl="3">
            <VxTextField
              v-model="form.name"
              :label="$t('settings.store.storeDialog.nameField.label')"
              name="name"
              :placeholder="
                $t('settings.store.storeDialog.nameField.placeholder')
              "
              rules="required|max:255"
              autocomplete="off"
            />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <VxTextField
              v-model="form.initials"
              :label="$t('settings.store.storeDialog.initialsField.label')"
              name="initials"
              :placeholder="
                $t('settings.store.storeDialog.initialsField.placeholder')
              "
              rules="required|max:3"
              autocomplete="off"
            />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <VxTextField
              v-model="form.storeNumber"
              :label="$t('settings.store.storeDialog.storeNumberField.label')"
              name="storeNumber"
              :placeholder="
                $t('settings.store.storeDialog.storeNumberField.placeholder')
              "
              rules="required|winmark_number"
              autocomplete="off"
            />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <VxAutocomplete
              v-model="form.timeZone"
              :label="$t('settings.store.storeDialog.timeZoneField.label')"
              name="timeZone"
              :placeholder="
                $t('settings.store.storeDialog.timeZoneField.placeholder')
              "
              rules="required"
              :items="timeZones"
              clearable
            />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <VxPhoneField
              v-model="form.landlineNumber"
              :label="$t('settings.store.storeDialog.phoneNumberField.label')"
              name="landlineNumber"
              :placeholder="
                $t('settings.store.storeDialog.phoneNumberField.placeholder')
              "
              persistent-hint
              rules="required|na_phone"
              autocomplete="off"
            />
          </v-col>
        </v-row>
      </VxForm>
      <VxAlert :type="$t('settings.store.storeDialog.newStoreAlert.type')">
        <i18n path="settings.store.storeDialog.newStoreAlert.message">
          <template #prefix>
            <b>{{ $t("settings.store.storeDialog.newStoreAlert.prefix") }}</b>
          </template>
        </i18n>
      </VxAlert>
    </v-container>
    <template #actions>
      <v-container>
        <v-row>
          <v-spacer />
          <VxBtn secondary :disabled="submitting" @click="cancel">
            {{ $t("settings.store.storeDialog.cancelBtnText") }}
          </VxBtn>
          <VxBtn text :loading="submitting" @click="onAddStore">
            {{ $t("settings.store.storeDialog.okBtnText") }}
          </VxBtn>
        </v-row>
      </v-container>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxDialog,
  VxForm,
  VxTextField,
  VxAutocomplete,
  VxPhoneField,
  VxAlert,
  VxBtn,
  useModelValue,
} from "@/core-ui";

export default {
  name: "StoreDialog",
  components: {
    VxDialog,
    VxForm,
    VxTextField,
    VxAutocomplete,
    VxPhoneField,
    VxAlert,
    VxBtn,
  },
  mixins: [
    useModelValue(),
    useFormObject({
      props: "store",
      default: {
        name: null,
        initials: null,
        storeNumber: null,
        landlineNumber: null,
        timeZone: null,
      },
    }),
  ],
  props: {
    timeZones: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    submitting: false,
  }),
  methods: {
    onAddStore() {
      this.$refs.form.submit();
    },
    cancel() {
      this.modelValue = false;
      this.$emit("cancel");
    },
    ok(context) {
      this.submitting = true;
      this.$emit("ok", {
        ...context,
        setErrors: (errors) => {
          this.submitting = false;
          context.setErrors(errors);
        },
        resolve: () => {
          this.modelValue = false;
          this.submitting = false;
          context.resolve();
        },
      });
    },
  },
};
</script>
